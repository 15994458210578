.galleryItem {
  cursor: pointer;
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
}
.image {
  max-width: 100%;
  border-radius: 4px;
}
.galleryItemTitle {
  padding: 8px 0;
}
