:root {
  --color-modal-bg: var(--color-n101);
}

.modal__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;

  display: none;
}
.modal__header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin-bottom: 16px;
}
.modal__bg__open {
  display: block;
}
.modal__title {
  font-size: 1.5em;
  font-weight: bold;
}

.model__close {
  display: grid;
  align-items: center;
  cursor: pointer;
}

.modal {
  color: var(--color-text);
  position: fixed;
  top: 0;
  left: 0;

  background-color: var(--color-modal-bg);
  border-radius: 4px;
  z-index: 101;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  width: 100vw;
}

@media screen and (min-width: 750px) {
  .modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 500px;
    max-width: 100%;
    max-height: 90vh;
    width: auto;
  }
}
