.downloadsList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
  list-style-type: none;
  max-width: 100%;
  gap: 8px;
  color: white;
}

@media screen and (min-width: 768px) {
  .downloadsList {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .downloadsList {
    grid-template-columns: repeat(4, 1fr);
  }
}

.img {
  max-width: 100%;
  border-radius: 9px;
}

.link {
  color: white;
}

.link:visited {
  color: white;
}

.images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  grid-area: image;
}

.generating {
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  align-items: center;
}

.mainTitle {
  text-align: center;
}

.processMessage {
  text-align: center;
}

.orderSummary {
  padding: 40px 0;
}

.download p,
.print p {
  margin: 0;
}

.download,
.print {
  background-color: #484848;
  padding: 8px;
  border-radius: 16px;
  border: 1px solid white;
  display: grid;
  gap: 12px;
}

.summaryImageNameList {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
