.increaseButton,
.decreaseButton {
  padding: 4px;
  border-color: transparent;
  stroke: var(--color-g100);
  background-color: transparent;
}

.qntLabel {
  text-align: right;
}

.quantity {
  display: grid;
  grid-area: quantity;
  grid-template-columns: auto auto 50px auto;
  gap: 4px;
  align-items: center;
  justify-content: end;
}

.quantity--nolabel {
  grid-template-columns: auto 50px auto;
}
