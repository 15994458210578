.page {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto;
  grid-template-areas:
    'crumbs'
    'title'
    'slider'
    'products';
  gap: 16px;
}

.page.pageGrid {
  grid-template-areas:
    'crumbs'
    'title'
    'grid'
    'products';
}

@media screen and (min-width: 768px) {
  .page {
    grid-template-rows: auto;
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'crumbs crumbs'
      'title title'
      'slider products';
  }
  .page.pageGrid {
    grid-template-areas:
      'crumbs crumbs'
      'title title'
      'grid grid';
  }
}
.gallery {
  grid-area: grid;
}
.slider {
  grid-area: slider;
}
.products {
  grid-area: products;
}
.title {
  grid-area: title;
}
.crumbs {
  grid-area: crumbs;
}

.productList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.product {
  display: grid;
  grid-template-columns: auto 1fr auto;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.currency {
  text-align: right;
}
.addToCart {
  width: 100%;
  margin-top: 32px;
}
.quantity {
  display: grid;
  grid-template-columns: auto 50px auto;
  gap: 4px;
}

.productGroupTitle {
  background-color: var(--footer-bg);
  padding: 16px;
}
