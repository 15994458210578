.productList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 16px;
}
.product {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-areas: 'title price quantity';
  justify-content: center;
  align-items: center;
  row-gap: 8px;
  column-gap: 24px;
  border: 1px solid var(--color-n100);
  border-radius: 8px;
  padding: 8px;
}
.withAddons {
  grid-template-areas:
    'title price quantity'
    'empty addons addons';
}
.currency {
  display: grid;
  /* grid-template-areas: 'discount price'; */
  /* grid-template-columns: 1fr auto; */
  column-gap: 8px;
  row-gap: 8px;
  text-align: right;
}
.withTiers {
  /* grid-template-areas:
    'discount price'
    'tiers tiers'; */
}
.quantity {
  display: grid;
  grid-template-columns: auto 50px auto;
  gap: 4px;
}

.addOns {
  grid-area: addons;
}
.addOnsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.addOn {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}
.multibuy {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.24px;
}

.multibuyTitle {
  font-weight: 700;
}
.currencyDiscounted {
  text-decoration: line-through;
  /* margin-right: 8px; */
  color: #999;
  text-align: right;
}
.tierTable {
  align-self: flex-end;
  grid-area: tiers;
  justify-self: flex-end;
  border-collapse: collapse;
}
.tierTable td {
  padding: 4px;
  border: 1px solid white;
}
