.button {
  border-radius: 40px;
  border-width: 1px;
  border-style: solid;
  padding: 8px 16px;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
  white-space: nowrap;
  cursor: pointer;
}

.linkButton {
  display: inline-grid;
}

.button--primary {
  background-color: var(--color-button-primary-bg);
  color: var(--color-button-primary-text);
  stroke: var(--color-button-primary-text);
  border-color: var(--color-button-primary-border);
}

.button--secondary {
  background-color: var(--color-button-secondary-bg);
  color: var(--color-button-secondary-text);
  stroke: var(--color-button-secondary-text);
  border-color: var(--color-button-secondary-border);
}

.button--tertiary {
  background-color: var(--color-button-tertiary-bg);
  color: var(--color-button-tertiary-text);
  stroke: var(--color-button-tertiary-text);
  border-color: var(--color-button-tertiary-border);
}

.button--quaternary {
  background-color: var(--color-button-quaternary-bg);
  color: var(--color-button-quaternary-text);
  stroke: var(--color-button-quaternary-bg);
  border-color: var(--color-button-quaternary-border);
  padding-left: 0;
  padding-right: 0;
  text-decoration: underline;
}
