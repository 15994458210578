.slideFigureImage {
  max-inline-size: 100%;
  object-fit: contain;
  border-radius: 8px;
  height: 300px;
  width: 600px;
}

@media screen and (min-width: 750px) {
  .slideFigureImage {
    height: 400px;
    width: 800px;
  }
}

.controlsRight {
  grid-row-start: 1;
  grid-column-start: 3;
  z-index: 99;
  align-self: center;
  transform: rotate(180deg);
}

.controlsLeft {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 99;
  align-self: center;
}

.slideFigure {
  margin: 0;
  transform: scale(0.9);
  transition: transform 0.3s cubic-bezier(0.25, 0, 0.3, 1);
  will-change: transform;
  display: grid;
  grid-gap: 8px;
}

.scroller {
  grid-row: 1;
  grid-column: 1/-1;
  display: grid;
  grid-auto-columns: 100%;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--_carousel-gutters);
  padding-block: var(--size-2) var(--_carousel-scrollbar-gutter);
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: var(--_carousel-gutters);
  padding-inline: var(--_carousel-gutters);
  scroll-behavior: smooth;
}

.slide {
  scroll-snap-align: center;
  transition: 1s ease-in-out transform;
  scroll-snap-stop: always;
  display: grid;
  align-items: center;
  justify-content: center;
}
.slideVisible .slideFigure {
  transform: scale(1);
}
.slider {
  scroll-snap-stop: always;
  align-self: start;
  display: grid;
  grid-template-columns: auto 1fr auto;
  justify-content: center;
}
.caption {
  color: var(--color-n200);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}
