.header {
  background-color: var(--nav-bg);
  display: grid;
  color: white;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.withShoppingCart,
.withFavourites {
  grid-template-columns: 1fr auto auto;
}

.withShippingAndFavourites {
  grid-template-columns: 1fr auto auto auto;
}

.logo {
  height: 32px;
  padding: 12px 16px;
}

.logo img {
  max-height: 100%;
}
