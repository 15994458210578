.crumb {
  color: #8d8d8d;
}
.crumb__active {
  text-decoration: none;
  color: inherit;
}
.crumb__separator {
  margin: 0 8px;
}
.crumbs {
  font-size: 18px;
  display: flex;
}
