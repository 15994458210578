.detailsForm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  grid-template-areas: 'firstName lastName' 'email email' 'phone phone';
}

.firstName {
  grid-area: firstName;
}
.lastName {
  grid-area: lastName;
  margin-top: 0px !important;
}
.email {
  grid-area: email;
}
.phone {
  grid-area: phone;
}
