.productGroupTitle {
  background-color: var(--footer-bg);
  margin: 0;
}

.montageButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.productSection {
  display: grid;
  gap: 16px;
}

.productList {
  display: grid;
  gap: 36px;
}
