.item {
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  width: 100vw;
  align-items: center;
  justify-content: center;
  position: relative;
}
.image {
  max-width: 100%;
  border-radius: 4px;
  user-select: none;
  user-drag: none;
}
.title {
  padding: 8px 0;
  text-align: center;
}
.favouriteButton {
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.5;
  z-index: 9;
}

.favourite {
  opacity: 1;
}
