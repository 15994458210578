:root {
  --spinner-size: 50px;
  --spinner-size-small: 20px;
  --spinner-size-large: 100px;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  width: var(--spinner-size);
  height: var(--spinner-size);
  justify-self: center;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(
    0deg,
    rgba(var(--color-g100-trip), 0.2) 33%,
    rgba(var(--color-g100-trip), 1) 100%
  );
  animation: rotation 1s linear infinite;
}

.spinner--small {
  width: var(--spinner-size-small);
  height: var(--spinner-size-small);
}

.spinner--large {
  width: var(--spinner-size-large);
  height: var(--spinner-size-large);
}

.spinner:after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(var(--spinner-size) - 4px);
  height: calc(var(--spinner-size) - 4px);
  border-radius: 50%;
  background: var(--color-black);
}

.spinner--small:after {
  width: calc(var(--spinner-size-small) - 4px);
  height: calc(var(--spinner-size-small) - 4px);
}

.spinner--large:after {
  width: calc(var(--spinner-size-large) - 4px);
  height: calc(var(--spinner-size-large) - 4px);
}

.spinner--color-inverted {
  background: linear-gradient(
    0deg,
    rgba(var(--color-black-trip), 0.2) 33%,
    rgba(var(--color-black-trip), 1) 100%
  );
}

.spinner--color-inverted:after {
  background: var(--color-g100);
}
