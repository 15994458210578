:root {
  --floating-nav-bg: grey;
}

.link {
  color: white;
  padding: 16px;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;
  cursor: pointer;
}

.nav {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  background-color: var(--floating-nav-bg);
  height: 100%;
  z-index: 99;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: 200px;
  transform: translateX(100%);
  transition: 250ms transform ease;
}

@media screen and (min-width: 750px) {
  .link {
    display: inline-flex;
    color: white;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    text-decoration: none;
  }
  .nav {
    position: relative;
    transform: none;
    background-color: transparent;
    flex-direction: row;
  }
}

.open {
  transform: translateX(0);
}

.topBar__menu {
  font-size: 24px;
  margin-right: 16px;
  cursor: pointer;
  color: white;
  height: 24px;
  width: 24px;
}

@media screen and (min-width: 750px) {
  .topBar__menu {
    display: none;
  }
}

.mainNav__bg {
  background-color: black;
  opacity: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  transition: 250ms opacity ease;
}
.mainNav__bg__open {
  display: block;
  opacity: 0.4;
  z-index: 8;
}
