.container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  grid-template-areas: 'basket' 'form';
}

@media screen and (min-width: 900px) {
  .container {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: 'form basket' 'form basket';
  }
}

@media screen and (min-width: 1150px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}

.accountOptions {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-content: center;
  align-items: center;
  grid-template-areas:
    'login divide guest'
    'loginbutton divide guestbutton'
    'loginsubtext divide guestsubtext';
}

.login {
  grid-area: login;
}
.guest {
  grid-area: guest;
  text-align: right;
}
.loginButton {
  grid-area: loginbutton;
  justify-self: flex-start;
}
.guestButton {
  grid-area: guestbutton;
  justify-self: flex-end;
  margin-bottom: 8px;
}
.or {
  grid-area: divide;
}
.loginSubtext {
  grid-area: loginsubtext;
}
.guestSubtext {
  grid-area: guestsubtext;
  text-align: right;
}

.form {
  grid-area: form;
}
.basket {
  grid-area: basket;
  border: 1px solid #302f2f;
  border-radius: 4px;
  padding: 16px;
  align-self: start;
}
.basketTitle {
  margin-top: 0;
}

.loggedInButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}
