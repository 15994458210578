/* Your styles goes here. */
.app {
  background-color: black;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: auto;
  height: 100%;
  gap: 60px;
  transition: filter 0.1s ease-in-out;
}

.main {
  padding: 0 16px;
}

.modalOpen {
  /* filter: blur(5px); */
}
