.page {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: minmax(0, auto);
  grid-template-areas:
    'title'
    'orders'
    'logout';
  grid-column-gap: 16px;
  grid-row-gap: 32px;
}

.title {
  grid-area: title;
  text-align: center;
}

.logout {
  grid-area: logout;
  justify-self: center;
}

.ordersTable {
  width: 100%;
}

.ordersTable th {
  text-align: left;
}

.ordersTable td {
  cursor: pointer;
}

.ordersTable ul {
  margin: 0;
  padding: 0;
}
.orderItem {
  display: grid;
  grid-template-areas:
    'image productName'
    'image imageName';
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  gap: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.orderItem__image {
  grid-area: image;
  max-width: 100px;
}

.orderItem__productName {
  grid-area: productName;
  margin: 0;
}

.orderItem__imageName {
  grid-area: imageName;
  margin: 0;
}
