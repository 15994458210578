.image {
  max-width: 100%;
  grid-area: image;
}

.addOns {
  grid-area: addons;
}
.price {
  grid-area: price;
  text-align: right;
}
.addOnsList {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 16px;
}
.addOn {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
}

.buttons {
  grid-area: buttons;
  display: grid;
  justify-content: end;
  grid-auto-flow: column;
  gap: 16px;
}
.addButton {
}
.cancelButton {
}
.addOnsTitle {
  margin-top: 0;
}
.imageGrid {
  grid-area: image;
}
.item {
  display: grid;
  align-items: center;
  row-gap: 16px;
  grid-template-areas:
    'image image image'
    'title quantity price'
    'addons addons addons'
    'buttons buttons buttons';
}
