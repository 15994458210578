.title {
  background-color: grey;
  border-radius: 4px;
  padding: 8px 8px;
  cursor: pointer;
}
.container {
  margin: 16px 0;
  border: 1px solid #302f2f;
  border-radius: 4px;
  padding: 16px;
}

.accordian + .accordian {
  margin-top: 8px;
}
