.slider {
  display: flex;
  touch-action: pan-x;
}

.container {
  position: relative;
  width: 100%;
  overflow: hidden;
  align-self: flex-start;
}

.next,
.previous {
  position: absolute;
  top: 50%;
  opacity: 0.75;
  transform: translateY(-50%);
}

.next {
  right: 0;
}

.previous {
  left: 0;
}
