.tac {
  text-align: center;
}
.link {
  text-decoration: none;
  color: var(--color-text-link);
}
.copyright {
  padding: 16px;
}

@media screen and (min-width: 768px) {
  .copyright {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    gap: 24px;
    display: grid;
  }
}
