.placeholder {
  background-color: grey;
  height: 100%;
  width: 100%;
  color: white;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  min-height: 125px;
}

@media screen and (min-width: 768px) {
  .placeholder {
    min-height: 200px;
  }
}
