.galleryPage {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

@media screen and (min-width: 768px) {
  .galleryPage {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .galleryPage {
    grid-template-columns: repeat(4, 1fr);
  }
}
