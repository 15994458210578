.items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  list-style-type: none;
  padding: 0;
  margin: 0;
  gap: 8px;
}

.image {
  max-width: 100%;
  border: 2px dashed #ccc;
  box-sizing: border-box;
  display: flex;
}

.placeholder {
  position: relative;
  border: 2px dashed #ccc;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  min-height: 110px;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* .deletable:hover {
  background-color: #f00;
  color: #fff;
} */

.deleteOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deleteOverlay:hover {
  opacity: 1;
}
.trashIcon {
  height: 50%;
}
