:root {
  --max-image-width: 100%;
}

.image {
  max-width: var(--max-image-width);
  grid-area: image;
}
.gridImage {
  max-width: 100%;
}
.info {
  display: grid;
  grid-template-columns: minmax(0, auto);
  gap: 8px;
  grid-template-areas:
    'image'
    'productName'
    'imageName';
  justify-content: center;
  align-items: center;
}
.noBorder {
  border: none;
}
.productName {
  grid-area: productName;
  align-self: end;
}
.remove {
  width: 24px;
}
.removeIcon {
  color: var(--color-button-primary-bg);
  cursor: pointer;
}
.imageName {
  grid-area: imageName;
  align-self: start;
  font-size: 0.8rem;
  margin: 0;
  padding-left: 0;
  list-style: none;
  color: var(--color-text-subdued);
}
.table th {
  text-align: left;
  font-size: 14px;
  /* width: 100%; */
}

.priceCell,
.priceTitle,
.subCell,
.quantCell,
.subTitle,
.quantTitle,
.totalTitle {
  text-align: right !important;
}

.quantCell {
  width: 105px;
}

.images {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px;
  grid-area: image;
}

th {
  padding: 16px;
}

td,
tbody th {
  padding: 32px 8px;
  box-sizing: border-box;
}
tr td,
tr th {
  border-bottom: 1px solid grey;
}

.table {
  border-spacing: 0;
  width: 100%;
}
.currencyDiscounted {
  text-decoration: line-through;
  /* margin-right: 8px; */
  color: #999;
  display: block;
}
