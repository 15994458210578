.footer {
  display: grid;
  padding: 16px;
  align-items: center;
  box-sizing: border-box;
  width: 100vw;
  justify-content: center;
  /* grid-auto-flow: column; */
  row-gap: 8px;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr;
}

.link {
  text-decoration: none;
  color: var(--color-text-very-subdued);
  font-size: 14px;
  padding: 0;
  margin: 0;
  text-align: center;
}

@media screen and (min-width: 750px) {
  .footer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
